<template>
  <div>
    <Frame title="CFDI timbrados y cancelados">
      <Layout>
        <Column size="10">
          <Layout>
            <Column size="3">
              <Field label="Fecha de inicio">
                <Datepicker v-model="model.StartDate" @input="onChangeValue" />
              </Field>
            </Column>
            <Column size="3">
              <Field label="Fecha fin">
                <Datepicker v-model="model.EndDate" :max="maxEndDate" :min="model.StartDate" />
              </Field>
            </Column>
            <Column size="3">
              <div>
                <Button
                  color="secondary"
                  icon="download"
                  class="custom-btn-report"
                  :disabled="!isValidFilter"
                  @click="() => getSummary('1')"
                >
                  Descargar CFDI timbrados
                </Button>
              </div>
            </Column>
            <Column size="3">
              <div>
                <Button
                  color="secondary-outlined"
                  icon="download"
                  class="custom-btn-report"
                  :disabled="!isValidFilter"
                  @click="() => getSummary('2')"
                >
                  Descargar CFDI Cancelados
                </Button>
              </div>
            </Column>
          </Layout>
        </Column>
      </Layout>
      <Layout class="centered-content">
        <Column size="3">
          <ActionCard
            :image="require('./../../../assets/summary-cfdi.svg')"
            text="Indica los parámetros a incluir para la generación de los reportes."
          />
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CFDISummary',
  data() {
    return {
      model: {
        StartDate: new Date(),
        EndDate: new Date(),
      },
    };
  },
  methods: {
    onChangeValue(value) {
      if (this.$moment(this.model.EndDate).isAfter(this.maxEndDate)) {
        this.model.EndDate = this.maxEndDate;
      } else if (this.$moment(this.model.EndDate).isBefore(value)) {
        this.model.EndDate = value;
      }
    },
    getSummary(reportType) {
      const OrgCode = localStorage.getItem('OCode');

      const query = {
        org: OrgCode,
        doc: 'STAMPCNXREPORT',
        organizationCode: OrgCode,
        lang: 'es',
        ReportFrom: this.$moment(this.model.StartDate).format('YYYY-MM-DD'),
        ReportTo: this.$moment(this.model.EndDate).format('YYYY-MM-DD'),
        ReportType: reportType,
        format: 'excel',
      };
      const queryString = new URLSearchParams(query).toString();

      const urlBase = `${process.env.VUE_APP_ROOT_API_ROOT}document/?${queryString}`;
      const link = document.createElement('a');
      link.setAttribute('href', urlBase);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
  },
  computed: {
    ...mapState('User', ['user']),
    maxEndDate() {
      const currentDate = new Date(this.model.StartDate);
      if (this.model.StartDate) {
        return new Date(currentDate.setMonth(this.model.StartDate.getMonth() + 6));
      }
      return null;
    },
    isValidFilter() {
      if (!this.model.StartDate || !this.model.EndDate) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.centered-content {
  justify-content: center;
  margin-top: 120px;
}
.custom-btn-report {
  flex: auto;
  width: 100%;
  margin-top: 24px;
}
</style>
